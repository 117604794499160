.App {
  text-align: center;
  /* background-color: yellow; */
  margin-top: 10%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: black;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  
}

.button button {
  background-color: green;
  border-radius: 20px;
  color: white;
  font-size: 20px;
  font-weight: bolder;
  font-family: Sans-serif;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.button button:hover {
  background-color: darkgreen;
}



